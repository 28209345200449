import React from 'react'
import { Head } from '~/components'
import { useApi } from '~/api'
import Details from '~/components/Details'
import Footer from '~/components/Footer'

const Detalhes = ({ location, pageContext }) => {
  const context = useApi(
    pageContext,
    (location.pathname.startsWith('/pacotes/') ? 'packages/' : 'shoppings/') +
      pageContext.slug
  )
  const { name } = context
  return (
    <>
      <Head
        location={location}
        title={name + ' - ' + process.env.GATSBY_SITE_NAME}
      />
      <Details pageContext={context} />
      <Footer />
    </>
  )
}

export default Detalhes
