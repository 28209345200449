import React, { useEffect, useRef, useState } from 'react'
import { useApi } from '~/api'
import { Link, navigate } from 'gatsby'
import api from '~/api'
import ReCAPTCHA from 'react-google-recaptcha'
import _InputMask from 'react-input-mask'
const whatsUrl = 'https://api.whatsapp.com/send'

import * as st from '~/assets/styl/ContactFormAside.module.styl'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

type RecaptchaProps = {
  className?: string
  inputRef: React.MutableRefObject<ReCAPTCHA>
}

export const Recaptcha = ({ inputRef: ref, className }: RecaptchaProps) => (
  <ReCAPTCHA
    ref={ref}
    sitekey="6LfQlx8gAAAAAHCnHDrGOmfl3iktSLsBkjei9Lbx"
    size="invisible"
    className={className}
  />
)

export const InputMask = ({
  mask,
  name,
  required = true,
  value,
  onChange,
  placeholder,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { mask: string }) =>
  placeholder && (
    <_InputMask mask={mask} maskChar={null} value={value} onChange={onChange}>
      {(inputProps) => (
        <input
          name={name}
          placeholder={placeholder}
          required={required}
          {...inputProps}
          {...props}
        />
      )}
    </_InputMask>
  )
export default function ContactFormAside(props) {
  const [buttonLabel, setButtonLabel] = useState('Enviar')
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const { itemId, unavailable } = props
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    privacy: false,
  })
  const [error, setError] = useState('')
  const whatsapps = useApi([], 'whatsapps')
  const [whatsapp, setWhatsapp] = useState('')

  useEffect(() => {
    if (pathname.includes('/pacotes')) {
      whatsapps.find((w) => w.name.includes('Pacotes') && setWhatsapp(w.phone))
    } else if (pathname.includes('/viagens')) {
      whatsapps.find((w) => w.name.includes('Viagem') && setWhatsapp(w.phone))
    }
  }, [pathname, whatsapps])

  const success = () => {
    navigate('/obrigado/')
  }

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  function handleSubmit(recaptcha: string) {
    setButtonLabel('Enviando...')
    if (pathname.includes('/pacotes')) {
      api(
        'post',
        'leads/package-interest',
        { ...data, package: itemId, recaptcha },
        { headers: { 'Content-Type': 'text/plain' } }
      ).then((data) => {
        if (data.code === 0) {
          success()
        } else {
          recaptchaRef.current.reset()
          let error = ''
          for (const key in data.errors) {
            error = data.errors[key]
            break
          }
          setError(error)
        }
      })
    } else {
      api(
        'post',
        'leads/shopping-interest',
        { ...data, shopping: itemId, recaptcha },
        { headers: { 'Content-Type': 'text/plain' } }
      ).then((data) => {
        if (data.code === 0) {
          success()
        } else {
          recaptchaRef.current.reset()
          let error = ''
          for (const key in data.errors) {
            error = data.errors[key]
            break
          }
          setError(error)
        }
      })
    }
  }

  return (
    <aside className={st.contactFormContainer}>
      <section className={st.formWrapper}>
        <h2
          style={{
            width: unavailable && 260,
            textAlign: unavailable && 'center',
          }}
        >
          Ficou interessado? Deixe seu contato
          {unavailable && <span> para a fila de espera</span>}.
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            recaptchaRef.current
              .executeAsync()
              .then((value) => handleSubmit(value))
          }}
        >
          <label>
            <input
              type="text"
              id="name"
              placeholder="Seu nome"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              required
            />
          </label>
          <label>
            <input
              type="email"
              id="email"
              placeholder="Seu e-mail"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              required
            />
          </label>
          <label>
            <InputMask
              mask={
                data.phone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'
              }
              type="tel"
              id="phone"
              placeholder="WhatsApp"
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              required
              pattern=".{14,15}"
              maxLength={15}
            />
          </label>
          <div className={st.privacy}>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                required
                onChange={(e) => setData({ ...data, privacy: e.target.value })}
              />
              <small>
                Declaro que li e aceito a{' '}
                <Link to="/politica-de-privacidade">
                  Política de privacidade
                </Link>
              </small>
            </label>
          </div>
          {error && <div>{error}</div>}
          <Recaptcha inputRef={recaptchaRef} />
          <button type="submit">{buttonLabel}</button>
        </form>
      </section>
      <section className={st.whatsappCta}>
        <a
          href={`${whatsUrl}?phone=55${whatsapp.replace(/\D/g, '')}`}
          target="_blank"
          rel="noreferrer external nofollow"
        >
          <h2>
            <span className="icon-whatsapp"></span>
            Chamar no WhatsApp
          </h2>
        </a>
      </section>
      <section className={st.shareWrapper}>
        <h2>Compartilhar</h2>
        <ul>
          <li>
            <WhatsappShareButton
              url={`https://www.bauerturismo.com${pathname}`}
            >
              <span className="icon-whatsapp"></span>
            </WhatsappShareButton>
          </li>
          <li>
            <FacebookShareButton
              url={`https://www.bauerturismo.com${pathname}`}
            >
              <span className="icon-facebook"></span>
            </FacebookShareButton>
          </li>
          <li>
            <LinkedinShareButton
              url={`https://www.bauerturismo.com${pathname}`}
            >
              <span className="icon-linkedin"></span>
            </LinkedinShareButton>
          </li>
          <li>
            <TwitterShareButton url={`https://www.bauerturismo.com${pathname}`}>
              <span className="icon-twitter"></span>
            </TwitterShareButton>
          </li>
        </ul>
      </section>
    </aside>
  )
}
