// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "Details-module--background-missing-image--qgEuW";
export var cardHeader = "Details-module--card-header--39fvN";
export var cardImage = "Details-module--card-image--cQu-N";
export var cardsWrapper = "Details-module--cards-wrapper--Q-7PF";
export var departing = "Details-module--departing--ogk1E";
export var detailsBanner = "Details-module--details-banner--FS4-F";
export var detailsContainer = "Details-module--details-container--fPlTX";
export var detailsWrapper = "Details-module--details-wrapper--L9wPK";
export var fullWidthCard = "Details-module--full-width-card--+n15T";
export var gallery = "Details-module--gallery--dW2nv";
export var galleryImages = "Details-module--gallery-images--l2mr-";
export var include = "Details-module--include--S3M7n";
export var includeDetails = "Details-module--include-details--s5C3V";
export var info = "Details-module--info--VWCxb";
export var infoDetails = "Details-module--info-details--CYSYf";
export var notInclude = "Details-module--not-include--xLNZg";
export var notIncludeDetails = "Details-module--not-include-details--+Npvv";
export var returning = "Details-module--returning--BOVuf";
export var schedule = "Details-module--schedule--CP1sj";
export var scheduleDetails = "Details-module--schedule-details--ctkgv";
export var tags = "Details-module--tags--PbpHJ";